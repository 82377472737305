/* eslint-disable func-names */

import { validateEmail } from './util';

const GenericCampaignPopUp = (() => {

    const cookieName = 'bfcampaign';
    const cookieTimeToLive = 30 * 24 * 60 * 60 * 1000;

    function setCookie() {
        const cookieValue = Date.now();
        document.cookie = `${cookieName}=${cookieValue};path=/`;
    }

    function getCookie() {
        const cookieNameToFind = `${cookieName}=`;
        const cookieList = document.cookie.split(';');
        for (let i = 0; i < cookieList.length; i++) {
            let cookie = cookieList[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieNameToFind) === 0) {
                return cookie.substring(cookieNameToFind.length, cookie.length);
            }
        }
        return null;
    }

    function checkCookieValidity(cookieValue) {
        return Date.now() - cookieValue > cookieTimeToLive;
    }

    function isToShowPopUp() {
        const cookieValue = getCookie();
        return cookieValue ? checkCookieValidity(cookieValue) : true;
    }

    function resetSubscriptionBlock() {
        $('#email-validation-error,#checkbox-validation-error').hide();
        $('input[id="form-email"]')
            .css('border-color', '')
            .val('');
        $('input[id="form-terms-popup"]').prop('checked', false);
    }

    function hasDetailsClassOrID($element) {
        const classList = Array.from($element.prop('classList'));
        const hasDetailsClass = classList.some((el) => el.indexOf('details') !== -1);
        const elementId = $element.attr('id');
        if (elementId) {
            return hasDetailsClass || elementId.indexOf('details') !== -1;
        }
        return hasDetailsClass;
    }

    function updateSubscriptionBlock($element) {
        const message = $('#success-message-text').data('succes-msg');
        $element.fadeOut('slow', function () {
            $(`<div id="success-message" class="generic-campaign-popup__success-message">${message}</div>`).insertAfter($(this));
        });
    }

    function togglePopUp() {
        const $clubsPopup = $('.js-generic-campaign-popup');
        $clubsPopup.toggleClass('show');

        if ($clubsPopup.hasClass('show')) {
            $('.js-generic-campaign-wrapper').show();
            $('div[id="success-message"]').hide();
        } else {
            resetSubscriptionBlock();
        }
    }

    $('.js-generic-campaign-popup-close').on('click', () => {
        togglePopUp();
    });

    function closeModal() {
        document.querySelector('.js-generic-campaign-popup-close').classList.remove('show');
    }

    $('.js-generic-campaign-popup--overlay').on('click', (event) => {
        if (!event.target.closest('.js-generic-campaign-url')) {
            closeModal();
        }
    });

    $('.js-generic-campaign-popup--overlay').on('click', (event) => {
        if (!event.target.closest('.js-generic-campaign-url')) {
            closeModal();
        }
    });

    $('#form-email, #form-email-details').on('input', (e) => {
        const $selectedEl = $(e.target);
        const detailsClass = hasDetailsClassOrID($selectedEl) ? '-details' : '';
        $(`span[id="email-validation-error${detailsClass}"]`).fadeOut('slow');
        $selectedEl.css('border-color', '');
    });

    $('#form-terms-popup, #form-terms-details').on('change', (e) => {
        const $selectedEl = $(e.target);
        const detailsClass = hasDetailsClassOrID($selectedEl) ? '-details' : '';
        if ($selectedEl.is(':checked')) {
            $(`span[id="checkbox-validation-error${detailsClass}"]`).fadeOut('slow');
        }
    });

    $('.js-generic-campaign-subscribe, .js-generic-campaign-subscribe-details').on('click', (e) => {
        const $button = $(e.target);
        const detailsClass = hasDetailsClassOrID($button) ? '-details' : '';

        const $email = $(`input[id="form-email${detailsClass}"]`);
        const $checkbox = $(`input[id="form-terms-popup${detailsClass}"]`);

        const $emailValidationError = $(`span[id="email-validation-error${detailsClass}"]`);
        const $checkboxValidationError = $(`span[id="checkbox-validation-error${detailsClass}"]`);

        const emailValue = $email.val();
        if (!emailValue) {
            $email.css('border-color', 'red');
            const requiredEmailMsg = $emailValidationError.data('validation-email-required');
            $emailValidationError.show().text(requiredEmailMsg);
            return;
        }

        if (!validateEmail(emailValue)) {
            $email.css('border-color', 'red');
            const invalidEmailMsg = $emailValidationError.data('validation-email-invalid');
            $emailValidationError.show().text(invalidEmailMsg);
            return;
        }

        if (!$checkbox.is(':checked')) {
            const checkboxValidationMsg = $checkboxValidationError.data('checkbox-validation-msg');
            $checkboxValidationError.show().text(checkboxValidationMsg);
            return;
        }

        $button.attr('disabled', true);
        const url = $('.js-generic-campaign-url').data('url');
        const payload = {
            email: $email.val(),
        };

        $.ajax({
            url,
            type: 'POST',
            data: payload,
            success(response) {
                if (!response.error) {
                    const $el = $(`.js-generic-campaign-wrapper${detailsClass}`);
                    updateSubscriptionBlock($el);
                } else {
                    console.error(response.error);
                }
                $button.attr('disabled', false);
            },
            error(err) {
                $button.attr('disabled', false);
                console.error(err);
            },
        });
    });

    function clickingOutside() {
        window.addEventListener('click', (e) => {
            if(document.querySelector('.js-generic-campaign-url').contains(e.target) || document.querySelector('.CybotCookiebotDialogContentWrapper').contains(e.target)) {
                return;
            } else {
                $('.js-generic-campaign-popup').removeClass('show');
            }
        });
    }

    return {
        init() {
            if (isToShowPopUp()) {
                setTimeout(() => {
                    togglePopUp();
                    setCookie();
                    clickingOutside();
                }, 3000);
            }
        },
    };
})();

export default (() => {
    if ($('.js-generic-campaign-popup').length > 0) {
        $(GenericCampaignPopUp.init);
    }
})();
