/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable strict */

'use strict';

function GtmEcommBuilder() {
    this.ecommParameters = {};
}

GtmEcommBuilder.prototype.withCheckout = function (value) {
    this.ecommParameters.checkout = value;
    return this;
};

GtmEcommBuilder.prototype.withCheckoutOption = function (value) {
    this.ecommParameters.checkout_option = value;
    return this;
};

GtmEcommBuilder.prototype.withPurchaseOption = function (value) {
    this.ecommParameters.purchase = value;
    return this;
};

GtmEcommBuilder.prototype.withCurrencyCode = function (value) {
    this.ecommParameters.currencyCode = value;
    return this;
};

GtmEcommBuilder.prototype.withImpressions = function (value) {
    this.ecommParameters.impressions = value;
    return this;
};

GtmEcommBuilder.prototype.withProductClick = function (value, actionField) {
    this.ecommParameters.click = { actionField, products: value };
    return this;
};

GtmEcommBuilder.prototype.build = function () {
    return this.ecommParameters;
};

module.exports = GtmEcommBuilder;
