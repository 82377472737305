import $ from 'jquery'; // eslint-disable-line import/no-extraneous-dependencies
import { processInclude } from './util';

window.$ = window.jQuery = $;

$(document).ready(() => {
    processInclude(require('./components/menu'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/dropdown'));
    processInclude(require('./components/accordionsGeneral'));
    processInclude(require('./components/header'));
    processInclude(require('./components/tableGeneral'));
    processInclude(require('./allinLetOpBanner'));
    processInclude(require('./gtm/gtmManager'));
    processInclude(require('./animations/generalAnimations'));
    processInclude(require('./animations/linesAnimations'));
    processInclude(require('./genericCampaignPopup'));
    processInclude(require('./allInAccordions'));
    processInclude(require('./customComponentOrder'));
});

require('./components/spinner');
