const GtmParametersBuilder = require('./gtmParametersBuilder');

const eventLabel = {
    login: 'login',
    logout: 'logout',
    mybf: 'my basic-fit',
};

function buildGtmParameters(label) {
    return new GtmParametersBuilder()
        .withEvent('eventPush')
        .withEventCategory('Button click')
        .withEventAction('main menu bar')
        .withEventLabel(label)
        .withParameter('eventValue', undefined)
        .withParameter('eventNonInteraction', false)
        .build();
}

export default function onReady() {
    $(document)
        .on('loginClicked', () => {
            const gtmParams = buildGtmParameters(eventLabel.login);
            window.dataLayer.push(gtmParams);
        })
        .on('logoutClicked', () => {
            const gtmParams = buildGtmParameters(eventLabel.logout);
            window.dataLayer.push(gtmParams);
        })
        .on('myBfClicked', () => {
            const gtmParams = buildGtmParameters(eventLabel.mybf);
            window.dataLayer.push(gtmParams);
        });
}
