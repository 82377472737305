module.exports = () => {
    /**
     * @function
     * @description Adds / removes class "moving"
     * based on the character limit in a text area
     */
    function handleBodyScrollClass() {
        const scroll = window.pageYOffset;
        const $body = $('body');
        if (scroll > 0) {
            $body.addClass('moving');
        } else {
            $body.removeClass('moving');
        }
    }

    $(window).on('load', () => {
        handleBodyScrollClass();
    }).on('scroll', () => {
        handleBodyScrollClass();
    }).on('resize', () => {
        handleBodyScrollClass();
    });

    // main mobile and tablet menu toggle
    $('.js-menu-toggle, .js-header-menu-item').on('click', () => {
        $('.site-menu').toggleClass('visible');
        $('body').toggleClass('stop-scrolling-on-touch');
    });

    $('.js-header-menu-item').on('click', function() {
        var hrefVal = $(this).attr('href');

        var headerHeight;

        if($('.js-top-banner').length) {
            headerHeight =  $('.js-top-banner').height() +  $('.main-navigation-wrapper').height();
        } else {
            headerHeight = $('.main-navigation-wrapper').height();
        }

        var distanceTop = $(hrefVal).offset().top - headerHeight;

        $('html, body').animate({
            scrollTop: distanceTop
        }, 2000);
    });

    if ($('.js-top-banner').length > 0) {
        $('body').addClass('has-top-promo-banner');
    }
    // top banner close button
    $('.js-close-top-banner').on('click', () => {
        $('body').removeClass('has-top-promo-banner');
        const url = $('.js-close-top-banner').attr('data-url');
        $.ajax({
            url,
        });
    });

    /**
     * @description Function for navigating to checkout and pushing gtm data to dataLayer
     */
    $('.js-become-member').on('click', () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: 'precheckout',
            step: '1',
            component_name: 'join',
        });
    });
};
