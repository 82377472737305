module.exports = () => {
    // Page designer custom components order

    if($('.custom-order').length) {
        $('.experience-component').each(function() {
            let dataOrder = $(this).children().attr("data-order");

            $(this).css({"order": dataOrder});
        });
    }
};

