const Dropdown = (() => {
    let dropdown = $('.js-dropdown');

    function dropdownToggle() {
        dropdown.find('.dropdown-toggle').on('click', e => {
            e.preventDefault();
            dropdown.find('.dropdown-menu').toggleClass('show');
        });
    }

    function dropdownClose() {
        $(document).on('click', e => {
            var $this = $(e.target);
            if (!$this.parents('.dropdown-toggle').length) {
                dropdown.find('.dropdown-menu').removeClass('show');
            }
        });
    }

    return {
        init() {
            dropdownToggle();
            dropdownClose();
        }
    }
})();

export default (() => {
    $(document).ready(function() {
        Dropdown.init();
    });
})();
