import { isDOMElementInViewport } from '../util';
var elementsOnScroll = document.querySelectorAll('.fade-in-animation');
var imagesOnScroll = document.querySelectorAll('.slide-in-from-right');
var elementsOnScrollDown = document.querySelectorAll('.fade-down-animation');

//These functions loop through the fetched lists, check if the elements are in the viewport
//if so add the animation class and remove the hidden class

function runFadeAnimation() {
    for (var i = 0; i < elementsOnScroll.length; i++) {
        var element = elementsOnScroll[i];

        if (isDOMElementInViewport(element)) {
            element.classList.add('fade-in-element');
            element.classList.remove('hidden');
        }
    }
}

function runSlideRightAnimation() {
    for (var i = 0; i < imagesOnScroll.length; i++) {
        var element = imagesOnScroll[i];

        if (isDOMElementInViewport(element)) {
            element.classList.add('slide-in-element');
            element.classList.remove('hidden');
        }
    }
}

function runFadeDownAnimation() {
    for (var i = 0; i < elementsOnScrollDown.length; i++) {
        var element = elementsOnScrollDown[i];

        if (isDOMElementInViewport(element)) {
            element.classList.add('fade-down-element');
            element.classList.remove('hidden');
        }
    }
}

runFadeAnimation();
runSlideRightAnimation();
window.addEventListener('scroll', runSlideRightAnimation);
runFadeDownAnimation();
window.addEventListener('scroll', runFadeAnimation);
window.addEventListener('scroll', runFadeDownAnimation);
