module.exports = () => {
    // General teble

    if($('.table-general').length) {
        $('.table-general').each(function() {
            let $tableCell = $(this).find('table th');

            if($tableCell.length > 4) {
                $(this).addClass('table-long');
            }
        });
    }
};