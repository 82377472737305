const clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').removeClass('show');
    $(element).closest('li').detach();
};

function toggleActiveClass(menuItems) {
    const pageType = $('.js-menu-item-type');
    if (pageType.val() !== '') {
        for (let i = 0; i < menuItems.length; i++) {
            const menuItem = $(menuItems[i]);
            if (menuItem.data('id') === pageType.val()) {
                menuItem.addClass('active');
            } else if (menuItem.hasClass('active')) {
                menuItem.removeClass('active');
            }
        }
    }
}

const handleMenuItems = function () {
    const menuItems = $('.js-header-menu-item');
    toggleActiveClass(menuItems);
    const allInMenuItems = $('.js-header-all-in-menu-item');
    toggleActiveClass(allInMenuItems);
};

module.exports = function () {
    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('mouseenter', function () {
            const eventElement = this;
            $('.navbar-nav > li').each(function () {
                if (!$.contains(this, eventElement)) {
                    $(this).find('.show').each(function () {
                        clearSelection(this);
                    });
                    if ($(this).hasClass('show')) {
                        $(this).removeClass('show');
                        $(this).children('ul.dropdown-menu').removeClass('show');
                        $(this).children('.nav-link').attr('aria-expanded', 'false');
                    }
                }
            });
            // need to close all the dropdowns that are not direct parent of current dropdown
            $(this).parent().addClass('show');
            $(this).siblings('.dropdown-menu').addClass('show');
        })
        .parent()
        .on('mouseleave', function () {
            $(this).removeClass('show');
            $(this).children('.dropdown-menu').removeClass('show');
        });

    $(document).ready(handleMenuItems);
};
