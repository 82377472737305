const GtmParametersBuilder = require('./gtmParametersBuilder');

function buildScrollDownBarOptions(eventLabel){
    return new GtmParametersBuilder()
        .withEvent('eventPush')
        .withEventCategory('Button click')
        .withEventAction('club page || scroll down option')
        .withEventLabel(eventLabel)
        .withParameter('eventValue', undefined)
        .withParameter('eventNonInteraction', false)
        .build();
}

function buildAddressLinkOptions(clubName){
    return new GtmParametersBuilder()
        .withEvent('eventPush')
        .withEventCategory('Button Click')
        .withEventAction('Club Detail - Route to Club')
        .withEventLabel(clubName)
        .build();
}

function buildViewMapOptions(clubName){
    return new GtmParametersBuilder()
        .withEvent('eventPush')
        .withEventCategory('Button Click')
        .withEventAction('Club Detail - View Map')
        .withEventLabel(clubName)
        .build();
}

export default function onReady() {
    $(document)
        .on('scrollDownBarClicked', (event, eventLabel) => {
            const gtmParams = buildScrollDownBarOptions(eventLabel);
            window.dataLayer.push(gtmParams);
        })
        .on('clubAddressLinkClicked', (event, clubName) => {
            const gtmParams = buildAddressLinkOptions(clubName);
            window.dataLayer.push(gtmParams);
        })
        .on('clubViewMapClicked', (event, clubName) => {
            const gtmParams = buildViewMapOptions(clubName);
            window.dataLayer.push(gtmParams);
        });
}
