const GtmParametersBuilder = require('./gtmParametersBuilder');

export default function onReady() {
    $(document).on('blogFilterClicked', (event, filter) => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Button Click')
            .withEventAction('Blog filter')
            .withEventLabel(filter);

        window.dataLayer.push(builder.build());
    });

    $(document).on('blogSocialShareClicked', (event, socialShare) => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Button Click')
            .withEventAction('Social Share')
            .withEventLabel(socialShare);

        window.dataLayer.push(builder.build());
    });


    $(document).on('blogPageLoaded', (event, blog) => {
        const builder = new GtmParametersBuilder()
            .withParameter('blogImage', blog.image)
            .withParameter('blogName', blog.name)
            .withParameter('blogPublishDate', blog.publishDate)
            .withParameter('blogPublishDateDay', blog.publishDateDay)
            .withParameter('blogPublishDateMonth', blog.publishDateMonth)
            .withParameter('blogPublishDateYear', blog.publishDateYear)
            .withParameter('blogWordCount', blog.wordCount);

        window.dataLayer.push(builder.build());
    });
}
