module.exports = () => {
    // All In accordions

    // close all accordions info content
    $('.all-in-accordion__info').hide();

    $('.js-all-in-accordion-button').on('click', (e) => {
        let accordionItem = $(e.currentTarget).closest('.all-in-accordion__item');

        accordionItem.toggleClass('open');
        // open and close accordion info content on button click
        accordionItem.find('.all-in-accordion__info').slideToggle('slow');
    });
};

