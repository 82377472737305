const GtmParametersBuilder = require('./gtmParametersBuilder');

export default function onReady() {
    $(document).on('subscriptionQuickLinkClicked', (event, quickLink) => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Subscription Flow')
            .withEventAction('Find Club - Filter click')
            .withEventLabel(quickLink);

        window.dataLayer.push(builder.build());
    });

    $(document).on('clubFinderQuickLinkClicked', (event, quickLink) => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Button Click')
            .withEventAction('Club Finder - Filter')
            .withEventLabel(quickLink);

        window.dataLayer.push(builder.build());
    });

    $(document).on('clubFinderResults', (event, hasResults) => {
        const builder = new GtmParametersBuilder()
            .withParameter('clubfinderResults', hasResults ? 'yes' : 'no');
        window.dataLayer.push(builder.build());
    });

    $(document).on('subscriptionViewMapClicked', () => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Subscription Flow')
            .withEventAction('Find Club - View Map click')
            .withEventLabel('View Map');

        window.dataLayer.push(builder.build());
    });

    $(document).on('clubFinderViewMapClicked', () => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Button Click')
            .withEventAction('Club Finder')
            .withEventLabel('View Map');

        window.dataLayer.push(builder.build());
    });

    $(document).on('joinNowClicked', (event) => {
        var selectedAddOns = [];
        $('.js-subscription-addon-cta').each(function() {
            if($(this).prop('checked')){
                selectedAddOns.push($(this).data('name'));
            }
        });

        var eventLabel = '';
        if(selectedAddOns.length > 1){
            for (let i = 0; i < selectedAddOns.length; i++) {
                const selectedAddon = selectedAddOns[i];
                if(i != selectedAddOns.length - 1){
                    eventLabel += selectedAddon + "|";
                }else{
                    eventLabel += selectedAddon;
                }
            }
        }else{
            eventLabel = selectedAddOns[0];
        }

        const builder = new GtmParametersBuilder()
        .withEvent('eventPush')
        .withEventCategory('Subscription Flow')
        .withEventAction('Add-Ons')
        .withEventLabel(eventLabel ? eventLabel: 'no add-ons selected');
        window.dataLayer.push(builder.build());
        window.localStorage.setItem('selectedAddOns', eventLabel ? eventLabel: 'no add-ons selected');
    });

    $(document).on('preselectedSubscription', (event, subscription) => {
        if (!subscription) {
            return;
        }
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Subscription Flow')
            .withEventAction('Membership - Subscription preselect')
            .withEventLabel(subscription);

        window.dataLayer.push(builder.build());
    });

    $(document).on('preselectedPayment', (event, subscription) => {
        if (!subscription) {
            return;
        }
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Subscription Flow')
            .withEventAction('Membership - Payment preselect')
            .withEventLabel(subscription);

        window.dataLayer.push(builder.build());
    });
    $(document).on('onSelectSubscriptionDuration', (event, subscription, duration) => {
        window.localStorage.setItem('selectedMembershipType', subscription);
        window.localStorage.setItem('selectedMembershipDuration', duration);
    });
}

