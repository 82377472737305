import { formatName } from './../util';

const GtmParametersBuilder = require('./gtmParametersBuilder');
const GtmEcommBuilder = require('./gtmEcommBuilder');

function buildHome(currencyCode, impressions) {
    const ecommData = new GtmEcommBuilder()
        .withCurrencyCode(currencyCode)
        .withImpressions(impressions.map(product => formatName(product)))
        .build();

    return new GtmParametersBuilder()
        .withEvent('ecomImpressions')
        .withEcommerce(ecommData)
        .build();
}

function buildHomePlan(productData) {
    const ecommData = new GtmEcommBuilder()
        .withProductClick(productData.map(product => formatName(product)), { list: 'homepage' })
        .build();

    return new GtmParametersBuilder()
        .withEvent('ecomProductClick')
        .withEcommerce(ecommData)
        .build();
}

function buildHomeContentAssets(contentAssetData) {
    const ecommData = new GtmParametersBuilder()
        .withEventCategory('Single sign-on|HOMEPAGE')
        .withEventAction(contentAssetData.eventAction)
        .withEventLabel(contentAssetData.eventLabel)
        .withEvent('eventPush')
        .build();

    return ecommData;
}

export default function onReady() {
    $(document).on('homePageLoaded', (event, currencyCode, impressions) => {
        const gtmParams = buildHome(currencyCode, impressions);
        window.dataLayer.push(gtmParams);

        if ($('.gtm-data').length) {
            // eslint-disable-next-line func-names
            $('.gtm-data').each(function () {
                let contentAssetData = {
                    eventAction: $(this).attr('data-gtm-action'),
                    eventLabel: $(this).attr('data-gtm-label'),
                };
                let gtmData = buildHomeContentAssets(contentAssetData);
                window.dataLayer.push(gtmData);
            });
        }
    });

    $(document).on('homePagePlanClicked', (event, productData) => {
        const gtmParams = buildHomePlan(productData);
        window.dataLayer.push(gtmParams);
    });
}
