
import tingle from 'tingle.js';
import getYouTubeID from 'get-youtube-id';
export const smallDesktopMin = 1025;
export const mobileViewMax = 767;
export const tabletViewMin = 901;
export const tabletViewMax = 1024;

function processInclude(include) {
    if (typeof include === 'function') {
        include();
    } else if (typeof include === 'object') {
        Object.keys(include).forEach((key) => {
            if (typeof include[key] === 'function') {
                include[key]();
            }
        });
    }
}

// Format name for ecommerce
function formatName(product) {
    if (product.category === 'subscription') {
        product.name = `basic-fit ${product.name.toLowerCase()}`;
    } else {
        product.name = product.name.toLowerCase();
    }
    return product;
}

function getScreenSizeType() {
    const innerWidth = window.innerWidth;

    if (innerWidth <= mobileViewMax) {
        return ['mobile-pt'];
    } else if (innerWidth > mobileViewMax && innerWidth <= 900) {
        return ['mobile-ls', 'tablet-pt'];
    } else if (innerWidth > 900 && innerWidth <= tabletViewMax) {
        return ['tablet-ls'];
    } else if (innerWidth > tabletViewMax) {
        return ['desktop'];
    }
}

// Better in terms of modularity than getScreenSizeType()
function getScreenSize(mediaQuery) {
    return window.matchMedia(mediaQuery).matches;
}

// Check if element is in viewport

function isElementInViewport(element) {
    const rect = document.querySelector(element).getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    const vertInView = (rect.top < windowHeight) && ((rect.top + rect.height) > 0);
    const horInView = (rect.left < windowWidth) && ((rect.left + rect.width) > 0);

    return (vertInView && horInView);
}

function isDOMElementInViewport(element) {
    const bounding = element.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    const vertInView = (bounding.top < windowHeight) && ((bounding.top + bounding.height) > 0);
    const horInView = (bounding.left < windowWidth) && ((bounding.left + bounding.width) > 0);

    return (vertInView && horInView);
}

function addClassWhenInViewport(element, className) {
    const $element = document.querySelector(element);

    if (document.querySelector(element)) {
        if (isElementInViewport(element)) {
            $element.classList.add(className);
        } else {
            $element.classList.remove(className);
        }
    }
}

/**
* Prevents body scroll in a way that IOS accepts it as well
* styles need to be written inline to work properly
* the added class is for adressing nesting inheritance
*/

let currentPageScrollPosition = 0;

function scrollLock(onoff) {
    const $body = document.querySelector('body');

    if (onoff === 'on') {
        currentPageScrollPosition = window.scrollY;
        $body.classList.add('scroll-lock');
        $body.style.overflow = 'hidden';
        $body.style.position = 'fixed';
        $body.style.top = `-${currentPageScrollPosition}px`;
        $body.style.width = '100%';
    } else if (onoff === 'off') {
        $body.classList.remove('scroll-lock');
        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, currentPageScrollPosition);
    }
}

/**
 * Create an alert to display the error message
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml =
        `<div class="alert alert-danger alert-dismissible valid-cart-error fade show" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        ${message}
    </div>`;

    $('.error-messaging').append(errorHtml);
}

function appendToUrl(url, params) {
    let newUrl = url;

    if (params) {
        newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');
    }

    return newUrl;
}

function openVideoInModal(videoUrl) {
    const videoId = getYouTubeID(videoUrl);

    const iframeVimeo = `<iframe width="100%" height="400" src="https://www.youtube.com/embed/${videoId}?rel=0&amp;showinfo=0&amp;controls=1&amp;autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

    const videoInModal = new tingle.modal({
        cssClass: ['modal-wrapper', 'modal-wrapper--video'],
        closeLabel: '',
        onClose() {
            this.destroy();
        },
    });

    videoInModal.setContent(iframeVimeo);
    videoInModal.open();
}

function scrollToAnchor(anchor, params = {}) {
    const defaultOptions = {
        headerElemHeight: params.headerElemHeight || 0,
        extraMargin: params.extraMargin || 0,
        duration: params.duration || 400,
    };
    const { headerElemHeight, extraMargin, duration } = defaultOptions;
    const scrollPos = parseInt($(anchor).offset().top - headerElemHeight - extraMargin);

    $('html, body').animate({
        scrollTop: scrollPos,
    }, duration);
}

function setEqualHeights (arrayItems, count) {
    if(arrayItems !== undefined && arrayItems.length > 0) {
        arrayItems.height('');

        var maxH = 0;

        if(count) {
            var arrays = [];
            while(arrayItems.length > 0) {
                arrays.push(arrayItems.splice(0, count));
            }

            for(var i = 0; i < arrays.length; i += 1) {
                var data = arrays[i];
                maxH = 0;
                for(var j = 0; j < data.length; j += 1) {
                    var currentH = $(data[j]).outerHeight();
                    if(currentH > maxH) {
                        maxH = currentH;
                    }
                }

                for(var k = 0; k < data.length; k += 1) {
                    $(data[k]).css('height', maxH);
                }
            }
        } else {
            arrayItems.each(function() {
                var currentH2 = $(this).outerHeight();
                if(currentH2 > maxH) {
                    maxH = currentH2;
                }
            });

            arrayItems.css('height', maxH);
        }
    }
}

function fontSizeReduced() {
    var pages = document.querySelectorAll('.page');
    var titleContainer = document.querySelector('.js-text-col');
    var fontReduceComponent = document.querySelectorAll('.js-font-component');

    pages.forEach((page) => {
        fontReduceComponent.forEach((component) => {
            if(component.offsetWidth > page.offsetWidth || component.offsetWidth > titleContainer.offsetWidth) {
                component.classList.add('font-smaller');
            }
        });
    });
}

function checkForContent(elements) {
    elements.forEach(function (item) {
        if(!item.hasChildNodes()) {
            item.remove();
        }
    });
}

/**
 * Checks if the email value entered is correct format
 * @param {string} email - email string to check if valid
 * @returns {boolean} Whether email is valid
 */
function validateEmail(email) {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    return regex.test(email);
}

function calculateTitleHeight() {
    const innerWidth = window.innerWidth;
    var heroBannerTitle = document.querySelector('.js-main-title-hero-wrapper');
    var heroBannerImg = document.querySelector('.js-hero-wrapper-content-img');

    if (heroBannerImg) {
        if (innerWidth < smallDesktopMin) {
            heroBannerImg.style.marginTop = heroBannerTitle.offsetHeight + 30 + 'px';
        }
        heroBannerImg.style.display = 'block';
        heroBannerImg.style.opacity = 1;
    } else {
        return;
    }
}

function openTabs(tabSelector, tabContentSelector) {
    const tab = tabSelector;
    const tabContent = tabContentSelector;

    function removeActive() {
        tab.forEach(function(element) {
            element.classList.remove('active');
        });

        tabContent.forEach(function(element) {
            element.style.display = 'none';
        });
    }

    tab.forEach(function(element) {
        element.addEventListener('click', function(e) {
            removeActive();

            let current = e.currentTarget;
            let currentID = e.currentTarget.getAttribute('data-id');
            let tabContent = document.getElementById(currentID);

            current.classList.add('active');
            tabContent.style.display = 'block';
        });
    });
}

//requires to define two hidden input fields that contains text for buttons
function readMoreButton(content, arrayItems, btnText, btnLessText, btnClassName, num, readLess) {
    var readMoreBtn = document.createElement('button');

    readMoreBtn.innerHTML = btnText.value;
    readMoreBtn.classList.add(btnClassName);

    //elements that needs to be hidden
    arrayItems.forEach((item, index) => {
        //from which element to show read more button
        if(index > num) {
            item.classList.add('hidden');

            //where to append btn
            content.appendChild(readMoreBtn);

            //logic for read more/less button
            readMoreBtn.addEventListener('click', function() {
                if(!readLess) {
                    item.classList.remove('hidden');
                    this.style.display = 'none';
                } else {
                    item.classList.toggle('hidden');
                }

                //changing text of the button
                if(item.classList.contains('hidden')) {
                    this.innerHTML = btnText.value;
                } else {
                    this.innerHTML = btnLessText.value;
                }
            });
        }
    });
}

function offsetAnchor(anchorLinks, elementOne, elementTwo) {
    anchorLinks.forEach(element => {
        element.addEventListener('click', function() {
            //get ID value from the link
            const idVal = element.getAttribute('data-id');

            //fetch section element with ID which is extracted from data-id attribute
            const sectionElement = document.getElementById(idVal);

            //calculate position of section element including also the header height
            let headerHeight;

            if(elementOne) {
                headerHeight = elementOne.offsetHeight + elementTwo.offsetHeight;
            } else {
                headerHeight = elementTwo.offsetHeight;
            }

            const sectionElementTop =  sectionElement.offsetTop - headerHeight;

            setTimeout(function() {
                window.scrollTo(window.scrollX, sectionElementTop);
            }, 0);
        });
    });
}

function imageResizeiOSFix() {
    const $img = $('.js-hero-wrapper-content-img img');
    if ($img) {
        $img.addClass('resize-fix');
    }
}

function backToTop(element) {
    const $backToTop = element;

    $(window).on('scroll', function() {
        var $scrollPos = window.pageYOffset;

        // 100 = one scroll
        if ($scrollPos > 100) {
            $backToTop.addClass('show');
        } else {
            $backToTop.removeClass('show');
        }
    });

    $backToTop.on('click', function(e) {
        e.preventDefault();

        $('html, body').animate({
            scrollTop: 0
        }, 500);
    });
}

function customStickyPosition(stickyElement, elementOne, elementTwo, elementThree) {
    let stickyEl = stickyElement;
    let offSetOne = 0;
    let offSetTwo = 0;
    let offSetThree = 0;

    if(elementOne) {
        offSetOne += elementOne.offsetHeight;
    }

    if(elementTwo) {
        offSetTwo += elementTwo.offsetHeight;
    }

    if(elementThree) {
        offSetThree += elementThree.offsetHeight;
    }

    stickyEl.style.top = offSetOne + offSetTwo + offSetThree + 'px';
}

function dynamicCountdown() {
    $('.js-promotion-text').each(function() {
        //get minutes from each promotion
        let timeLeftSeconds = parseFloat($(this).text(), 10) * 60;

        let self = $(this);

        var timer = setInterval(function(){
            //get hours
            let hours = Math.floor(timeLeftSeconds / 3600);
            //get minutes
            let minutes = Math.floor((timeLeftSeconds - (hours * 3600)) / 60);
            //get seconds
            let seconds = timeLeftSeconds - (hours * 3600) - (minutes * 60);

            hours < 10 ? hours = '0' + hours : '';
            minutes < 10 ? minutes = '0' + minutes : '';
            seconds < 10 ? seconds = '0' + seconds : '';

            let remainingTime =  hours + ':' + minutes + ':' + seconds;

            if(timeLeftSeconds <= 0){
                clearInterval(timer);
            }

            //when string concatenate pump text
            if (remainingTime) {
                //set remaining time to each label
                self.text(remainingTime);
                if(!$(".discount-label__promotion").hasClass("js-loaded")) {
                    $('.discount-label__promotion').addClass('js-loaded');
                    $.spinner().stop();
                }
            }

            timeLeftSeconds -= 1;
        }, 1000);
    });
}

/**
 * @description Should be done before slider init to remove slides so we can use different number of slider assets per locale, for non sliders it should alse be removed at the beginning.
 * @param {jQuery} $Selector - selector of element that has childred to hide
 * @param {string} parentToRemoveSelector - selector of parent element that should be removed.
 */
function removeHiddenAssets($Selector, parentToRemoveSelector) {
    if ($Selector.find('.js-remove-element')) {
        $Selector.find('.js-remove-element').closest(parentToRemoveSelector).remove();
    }
}

export {
    processInclude,
    getScreenSizeType,
    isElementInViewport,
    isDOMElementInViewport,
    addClassWhenInViewport,
    appendToUrl,
    createErrorNotification,
    getScreenSize,
    openVideoInModal,
    scrollToAnchor,
    formatName,
    setEqualHeights,
    fontSizeReduced,
    calculateTitleHeight,
    validateEmail,
    openTabs,
    readMoreButton,
    checkForContent,
    offsetAnchor,
    backToTop,
    imageResizeiOSFix,
    customStickyPosition,
    dynamicCountdown,
    scrollLock,
    removeHiddenAssets
};
