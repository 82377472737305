const GtmParametersBuilder = require('./gtmParametersBuilder');

export default function onReady() {
    $(document).on('click', '.js-related-article__link', e => {
        const $target = $(e.currentTarget);
        const category = $target.data('category');
        const title = $target.data('title');

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });

        const event = new GtmParametersBuilder()
            .withEvent('blog')
            .withParameter('blog_category', category)
            .withParameter('blog_title', title.toUpperCase())
            .build();

        window.dataLayer.push(event);
    });
}
