module.exports = () => {
    // General accordions

    const bigScreenViewMin = 1025;

    // close all accordions info content
    $('.accordion__info').hide();

    $('.js-accordion-general-button').on('click', e => {
        const accordionItem = $(e.currentTarget).closest('.accordion__item');

        accordionItem.toggleClass('open');
        // open and close accordion info content on button click
        accordionItem.find('.accordion__info').slideToggle('slow');
    });

    if ($(window).width() < bigScreenViewMin && !$('.accordions__block').hasClass('no-collapse')) {
        $('.accordions__block').hide();

        // Open and close whole accordion block on mobile and tablets
        $('.js-accordion-general-mobile-button').on('click', e => {
            const accordionBlock = $(e.currentTarget).closest('.accordions__inner');

            accordionBlock.toggleClass('mobile-open');
            // open and close accordion info content on button click
            accordionBlock.find('.accordions__block').slideToggle('slow');
        });
    }
};
