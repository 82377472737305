import { processInclude } from './../util';

export default function onReady() {
    if (window.googleAnalyticInitialized) {
        const isHome = !!$('#home.page-home').length;
        const isCheckout = !!$('.page.js-checkout-page').length;
        const isFinder = !!$('.page.js-club-finder').length;

        try {
            if (isCheckout) {
                processInclude(require('./subscriptionFlow'));
                processInclude(require('./checkoutFlow'));
            }

            if (!(isHome || isFinder)) {
                processInclude(require('./blogPages'));
                processInclude(require('./personalSupportPages'));
                processInclude(require('./clubPages'));
                processInclude(require('./recruitmentPage'));
                processInclude(require('./store'));
                processInclude(require('./article'));
            }

            processInclude(require('./homePage'));
            processInclude(require('./mainMenuBar'));
        } catch (e) {
            if (!typeof console === undefined) {
                // eslint-disable-next-line no-console
                console.log(`Error with tracking script: ${e.message}`);
            }
        }
    }
}
