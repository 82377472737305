/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable strict */

'use strict';

function GtmCheckoutBuilder() {
    this.checkoutParameters = {};
}

GtmCheckoutBuilder.prototype.withActionField = function (value) {
    this.checkoutParameters.actionField = value;
    return this;
};

GtmCheckoutBuilder.prototype.withProducts = function (value) {
    this.checkoutParameters.products = value;
    return this;
};

GtmCheckoutBuilder.prototype.withMarketingID = function (value) {
    this.checkoutParameters.marketingID = value;
    return this;
};

GtmCheckoutBuilder.prototype.build = function () {
    return this.checkoutParameters;
};

module.exports = GtmCheckoutBuilder;
