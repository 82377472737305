const GtmParametersBuilder = require('./gtmParametersBuilder');

export default function onReady() {
    $(document).on('personalSupportFilterClicked', (event, filter) => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Button Click')
            .withEventAction('Personal Support - Filter')
            .withEventLabel(filter);

        window.dataLayer.push(builder.build());
    });

    $(document).on('personalSupportContacted', (event, name) => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory('Forms')
            .withEventAction('Personal Support - Booking')
            .withEventLabel(name);

        window.dataLayer.push(builder.build());
    });
}
