const GtmParametersBuilder = require('./gtmParametersBuilder');

/**
 * Gets store data and pushes GTM event to the data layer
 * @param {string} id - Store id
 * @param {string} name - Store name
 * @param {string} tags - Store facilities
 */
const pushGtmEvent = (id, name, tags) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    const event = new GtmParametersBuilder()
        .withEvent('discover_club')
        .withParameter('item_brand', name)
        .withParameter('item_category2', tags)
        .withParameter('item_category3', id)
        .build();

    window.dataLayer.push(event);
};

export default function onReady() {
    $(document).on('click', '.js-club-tile', e => {
        const $target = $(e.currentTarget);
        const $listItems = $target.find('.js-trainer-tile__tags li');
        const id = $target.data('pid');
        const name = $target.data('name');
        const tags = $listItems.map((i, item) => $(item).text()).get().join('|');

        if (id && name && tags) {
            pushGtmEvent(id, name, tags);
        }
    });

    $(document).on('click', '.js-result-item', e => {
        const $target = $(e.currentTarget);
        const id = $target.data('pid');
        const name = $target.data('name');
        const tags = $target.data('tags');

        if (id && name && tags) {
            pushGtmEvent(id, name, tags);
        }
    });
}
