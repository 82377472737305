const GtmParametersBuilder = require('./gtmParametersBuilder');

const buttonClickEvent = 'Button Click';


export default function onReady() {
    $(document).on('recruitmentPageButtonClick', (event, section, menu) => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory(buttonClickEvent)
            .withEventAction(`${section} - ${menu}`)
            .withEventLabel(menu);

        window.dataLayer.push(builder.build());
    });

    $(document).on('recruitmentPageSearch', (event, query) => {
        const builder = new GtmParametersBuilder()
            .withEvent('eventPush')
            .withEventCategory(buttonClickEvent)
            .withEventAction('Search Jobs - Search')
            .withEventLabel('Search')
            .withParameter('event', 'search')
            .withParameter('query', query);

        window.dataLayer.push(builder.build());
    });
}
