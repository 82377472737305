/**
 * Gets redirect URL from server and redirects to it
 * @param {string} url - URL to send request to
 * @param {Object} data - Request data to send with request
 */
const redirect = (url, data) => {
    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        data,
        success: response => {
            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }
        },
    });
};

module.exports = () => {
    $('.country-selector a').on('click', e => {
        e.preventDefault();

        let action = $('.page').data('action');
        let queryString = $('.page').data('querystring');

        const $target = $(e.currentTarget);
        const url = $('.country-selector').data('url');
        const code = $target.data('locale');
        const CurrencyCode = $target.data('currencycode');
        const trainerId = $('.page').data('trainerid');
        const $pageDesignerPage = $('.page-designer-reference');
        const pageDesignerPageId = $pageDesignerPage.find('div[id]').attr('id');

        if (!action && !queryString && pageDesignerPageId) {
            action = 'Page-Show';
            queryString = `cid=${pageDesignerPageId}`;
        }

        redirect(url, {
            code,
            queryString,
            CurrencyCode,
            action,
            trainerId,
        });
    });

    $('.js-mobile-language-selector, .js-country-selector').on('change', e => {
        e.preventDefault();

        let action = $('.page').data('action');
        let queryString = $('.page').data('querystring');
        const $target = $(e.currentTarget);
        const url = $target.data('url');
        const code = $target.find(':selected').data('locale');
        const CurrencyCode = $target.find(':selected').data('currencycode');
        const trainerId = $('.page').data('trainerid');
        const $pageDesignerPage = $('.page-designer-reference');
        const pageDesignerPageId = $pageDesignerPage.find('div[id]').attr('id');

        if (!action && !queryString && pageDesignerPageId) {
            action = 'Page-Show';
            queryString = `cid=${pageDesignerPageId}`;
        }

        redirect(url, {
            code,
            queryString,
            CurrencyCode,
            action,
            trainerId,
        });
    });

    window.addEventListener('CookiebotOnAccept', () => {
        if (!window.Cookiebot.consented) {
            return;
        }

        const $selectedOption = $('.js-country-selector li.selected a');
        if (!$selectedOption.length) {
            return;
        }

        const defaultCountry = {
            id: `${$selectedOption.data('language')}_${$selectedOption.data('country-code')}`,
            country: $selectedOption.data('country-code'),
            currencyCode: $selectedOption.data('currency-code'),
        };
        const defaultCountryJSON = JSON.stringify(defaultCountry).replace(/"/g, '\\"');
        document.cookie = `defaultCountry="${defaultCountryJSON}"; expires=Session; path=/`;
    });
};
