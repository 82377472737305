/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable strict */

'use strict';

function GtmParametersBuilder() {
    this.gtmParameters = {};
}

GtmParametersBuilder.prototype.withEvent = function (value) {
    this.gtmParameters.event = value;
    return this;
};

GtmParametersBuilder.prototype.withEventCategory = function (value) {
    this.gtmParameters.eventCategory = value;
    return this;
};

GtmParametersBuilder.prototype.withEventAction = function (value) {
    this.gtmParameters.eventAction = value;
    return this;
};

GtmParametersBuilder.prototype.withEventLabel = function (value) {
    this.gtmParameters.eventLabel = value;
    return this;
};

GtmParametersBuilder.prototype.withEcommerce = function (value) {
    this.gtmParameters.ecommerce = value;
    return this;
};

GtmParametersBuilder.prototype.withUser = function (value) {
    this.gtmParameters.user = value;
    return this;
};

GtmParametersBuilder.prototype.withParameter = function (name, value) {
    this.gtmParameters[name] = value;
    return this;
};

GtmParametersBuilder.prototype.withPage = function (data) {
    const country = data.locale.split('_')[1].toUpperCase();
    const language = data.locale.split('_')[0].toUpperCase();
    this.gtmParameters.page = {
        page_type: data.type,
        page_country: country,
        page_location: data.location,
        page_title: data.title,
        page_language: language,
    };
    return this;
};

GtmParametersBuilder.prototype.withTraffic = function (data) {
    this.gtmParameters.traffictype = {
        platform: 'web',
        ip_address: data.ip,
    };
    return this;
};

GtmParametersBuilder.prototype.withError = function (value) {
    this.gtmParameters.error = value;
    return this;
};

GtmParametersBuilder.prototype.build = function () {
    return this.gtmParameters;
};

module.exports = GtmParametersBuilder;
