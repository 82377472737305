import KUTE from 'kute.js';
import { getScreenSizeType } from '../util';

const isDesktop = getScreenSizeType().indexOf('desktop') > -1;

function runCircleAnimationBackwards(outerCircleId, innerCircleId) {
    const el1 = document.querySelector(outerCircleId);
    const el2 = document.querySelector(innerCircleId);

    if (el1 && el2) {
        KUTE.fromTo(outerCircleId,
            { draw: '25% 100%', rotate: 45, opacity: 1 },
            { draw: '0% 25%', opacity: 1 },
            { easing: 'easingCubicInOut', yoyo: true, duration: 1500 }
        ).start();

        KUTE.fromTo(innerCircleId,
            { draw: '25% 100%' },
            { draw: '0% 25%' },
            { easing: 'easingCubicInOut', yoyo: true, duration: 1500 }
        ).start();
    }
}

function runCircleAnimationForwards(outerCircleId, innerCircleId) {
    const el1 = document.querySelector(outerCircleId);
    const el2 = document.querySelector(innerCircleId);

    if (el1 && el2) {
        KUTE.fromTo(outerCircleId,
            { draw: '0% 25%', rotate: 45, opacity: 1 },
            { draw: '25% 100%', opacity: 1 },
            { easing: 'easingCubicInOut', yoyo: true, duration: 1500 }
        ).start();

        KUTE.fromTo(innerCircleId,
            { draw: '0% 25%', opacity: 1 },
            { draw: '25% 100%', opacity: 1 },
            { easing: 'easingCubicInOut', yoyo: true, duration: 1500 }
        ).start();
    }
}

function runLineAnimationBackwards(id, animationTime = 1500) {
    const el = document.querySelector(id);

    if (el) {
        KUTE.fromTo(id,
            { draw: '100% 100%', opacity: 1 },
            { draw: '0% 100%', opacity: 1 },
            { easing: 'easingCubicInOut', yoyo: true, duration: animationTime }
        ).start();
    }
}

function runLineAnimationForwards(id, animationTime = 1500) {
    const el = document.querySelector(id);

    if (el) {
        KUTE.fromTo(id,
            { draw: '0% 25%', opacity: 1 },
            { draw: '0% 100%', opacity: 1 },
            { easing: 'easingCubicInOut', yoyo: true, duration: animationTime }
        ).start();
    }
}

function runLineAnimationBounce(id) {
    if (id) {
        KUTE.fromTo(id,
            { draw: '0% 100%' },
            { draw: '0% 25%' },
            { easing: 'easingCubicInOut', yoyo: true, duration: 1500 }
        ).start();
    }
}

function runLineAnimationBounceBack(id) {
    if (id) {
        KUTE.fromTo(id,
            { draw: '0% 25%', rotate: 45, opacity: 1 },
            { draw: '0% 100%', opacity: 1 },
            { easing: 'easingCubicInOut', yoyo: true, duration: 1500 }
        ).start();
    }
}

function lineScrollAnimation(lineId) {
    if (isDesktop && lineId) {
        // Get the id of the <path> element and the length of <path>
        const linePath = document.getElementById(lineId);
        const length = linePath.getTotalLength();

        // The start position of the drawing
        linePath.style.strokeDasharray = length;

        // Hide the line by offsetting dash. Remove this line to show the line before scroll draw
        linePath.style.strokeDashoffset = length;
        const scrollpercent = 1 - (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);

        const draw = length * scrollpercent;

        // Reverse the drawing (when scrolling upwards)
        linePath.style.strokeDashoffset = length - draw;
    }
}

function imageScrollAnimation(svgId) {
    if (isDesktop && svgId) {
        const animationBanner = document.querySelector('.line-animation-block').getBoundingClientRect();
        const animatedSvg = document.getElementById(svgId);
        const navigationHeight = document.querySelector('.main-navigation-wrapper').getBoundingClientRect().height;
        const devider = svgId.includes('loginbanner') ? 18 : 8;

        const maxHeight = (-animationBanner.height / devider) + 20; // added 20px for padding to avoid content overlap

        // if the banner scrolls out of the viewport, the image becomes sticky until the max sticky point is reached
        if (animationBanner.top <= navigationHeight && animationBanner.top > maxHeight) {
            animatedSvg.style.position = 'fixed';
            animatedSvg.style.top = navigationHeight;
        } else {
            animatedSvg.style.position = 'relative';
            animatedSvg.style.top = 'auto';

            if (animationBanner.top <= maxHeight) {
                animatedSvg.style.top = -maxHeight + navigationHeight;
            }
        }
    }
}

export {
    runCircleAnimationBackwards,
    runCircleAnimationForwards,
    runLineAnimationBackwards,
    runLineAnimationForwards,
    runLineAnimationBounce,
    runLineAnimationBounceBack,
    lineScrollAnimation,
    imageScrollAnimation,
};
